import Service from '../Service'

export default class extends Service {
  constructor (id) {
    super(['stock-control', 'purchase-orders', id, 'inbound'])
  }

  getQC () {
    return this.get(null, '/qc')
  }

  getPutaway () {
    return this.get(null, '/putaway')
  }

  count (data) {
    return this.post(data)
  }

  qc (data) {
    return this.post(data, '/qc')
  }

  putAway (data) {
    return this.post(data, '/putaway')
  }
}
