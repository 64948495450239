<template>
  <gf-table :data="data" :scrollable="false" @selection-change="selectionChange">
    <el-table-column type="selection">
    </el-table-column>
    <el-table-column label="Item Name" min-width="220" prop="name">
      <template slot-scope="slot">
        <div class="gf-item">
          <div v-if="slot.row.variant.name === slot.row.product.name && slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.variant.name }}</div>
          <div v-else-if="slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.product.name }} - {{ slot.row.variant.name }}</div>
          <div v-else class="gf-item__name">
            <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
            <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
            <span>(Pack of {{ slot.row.variant.pack_size }})</span>
          </div>
          <div class="gf-item__sub">
            <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Quantity" min-width="100">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.inbound[0].expected - slot.row.inbound[0].received_qty) }}
      </template>
    </el-table-column>
    <el-table-column label="Usable" min-width="100">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.inbound[0].actual - slot.row.inbound[0].received_qty) }}
      </template>
    </el-table-column>
    <el-table-column label="Not Usable" min-width="100">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.inbound[0].expected - slot.row.inbound[0].actual) }}
      </template>
    </el-table-column>
    <el-table-column label="Remarks" min-width="150" prop="remarks">
      <template slot-scope="slot">
        {{ slot.row.inbound[0].remarks }}
      </template>
    </el-table-column>
    <el-table-column label="QC Date" min-width="100">
      <template slot-scope="slot">
        {{ $DateFormat(slot.row.inbound[0].created_at) }}
      </template>
    </el-table-column>
  </gf-table>
</template>

<script>
export default {
  props: {
    data: Array
  },
  methods: {
    selectionChange (selection) {
      this.$emit('selection-change', selection)
    }
  }
}
</script>
