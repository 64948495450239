<template>
  <gf-table :data="data" :scrollable="false" :summary-method="getItemSummary" show-summary @selection-change="selectionChange">
    <el-table-column type="selection">
    </el-table-column>
    <el-table-column label="Item Name" min-width="200" prop="name">
      <template slot-scope="slot">
        <div class="gf-item">
          <div v-if="slot.row.variant.name === slot.row.product.name && slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.variant.name }}</div>
          <div v-else-if="slot.row.variant.is_pack === 0" class="gf-item__name">{{ slot.row.product.name }} - {{ slot.row.variant.name }}</div>
          <div v-else class="gf-item__name">
            <span v-if="slot.row.product.name === slot.row.variant.variant.name">{{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
            <span v-else>{{ slot.row.product.name }} - {{ slot.row.variant.variant.name }} - {{ slot.row.variant.name }}</span>
            <span>(Pack of {{ slot.row.variant.pack_size }})</span>
          </div>
          <div class="gf-item__sub">
            <span class="gf-item__sub__sku">SKU: {{ slot.row.variant.sku }}</span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Quantity" min-width="100" prop="quantity">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.quantity) }}
      </template>
    </el-table-column>
    <el-table-column label="After" min-width="100" prop="after">
      <template slot-scope="slot">
        <span v-if="slot.row.variant.is_pack">{{ $NumberFormat(parseFloat(slot.row.quantity) + parseFloat((slot.row.stocks.find(st => st.warehouse_id === po.ship_to_id) || { value: 0.0 }).value) / parseFloat(slot.row.variant.pack_size)) }}</span>
        <span v-else>{{ $NumberFormat(parseFloat(slot.row.quantity) + parseFloat((slot.row.stocks.find(st => st.warehouse_id === po.ship_to_id) || { value: 0.0 }).value)) }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Cost" min-width="120" prop="cost">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.cost) }}
      </template>
    </el-table-column>
    <el-table-column v-if="po.is_inclusive !== 2" label="Tax" min-width="100" prop="tax">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.tax) }}%
      </template>
    </el-table-column>
    <el-table-column label="Total" min-width="100" prop="total">
      <template slot-scope="slot">
        {{ $NumberFormat(slot.row.quantity * slot.row.cost) }}
      </template>
    </el-table-column>
  </gf-table>
</template>

<script>
export default {
  props: {
    po: Object,
    data: Array
  },
  methods: {
    selectionChange (selection) {
      this.$emit('selection-change', selection)
    },
    // summary
    getItemSummary (param) {
      const { data } = param
      const sums = ['', 'Item Subtotal', '0.00', '', '', '0.00', '0.00']

      sums[2] = this.$NumberFormat(data.map(item => parseFloat(item.quantity)).reduce((pval, val) => pval + val, 0))
      sums[5] = this.$NumberFormat(data.map(item => parseFloat(item.cost) * parseFloat(item.quantity) * (parseFloat(item.tax) / 100)).reduce((pval, val) => pval + val, 0))
      sums[6] = this.$NumberFormat(data.map(item => parseFloat(item.cost) * parseFloat(item.quantity)).reduce((pval, val) => pval + val, 0))

      if (this.po.is_inclusive !== 2) {
        sums[5] = this.$NumberFormat(data.map(item => parseFloat(item.cost) * parseFloat(item.quantity) * (parseFloat(item.tax) / 100)).reduce((pval, val) => pval + val, 0))
        sums[6] = this.$NumberFormat(data.map(item => parseFloat(item.cost) * parseFloat(item.quantity)).reduce((pval, val) => pval + val, 0))
      } else {
        sums[5] = this.$NumberFormat(data.map(item => parseFloat(item.cost) * parseFloat(item.quantity)).reduce((pval, val) => pval + val, 0))
        sums[6] = ''
      }
      return sums
    }
  }
}
</script>
