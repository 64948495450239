<template>
  <gf-content v-loading="loading" overflow>
    <template #title>
      <div class="title">
        <span class="font-weight-normal mr-3">{{ po.supplier.name }}</span>
        <gf-label v-if="po.status === 1" inline light pill type="dark"
          >Draft</gf-label
        >
        <gf-label v-else-if="po.status === 2" inline light pill type="primary"
          >For Approval</gf-label
        >
        <gf-label v-else-if="po.status === 3" inline light pill type="danger"
          >Cancelled</gf-label
        >
        <gf-label v-else-if="po.status === 4" inline pill type="danger"
          >Rejected</gf-label
        >
        <gf-label v-else-if="po.status === 5" inline pill type="primary"
          >Active</gf-label
        >
        <gf-label v-else-if="po.status === 6" inline light pill type="info"
          >Receiving</gf-label
        >
        <gf-label v-else-if="po.status === 7" inline pill type="info"
          >Received</gf-label
        >
        <gf-label v-else-if="po.status === 8" inline light pill type="warning"
          >Void</gf-label
        >
      </div>
    </template>
    <template #toolbar>
      <gf-button
        v-if="po.status === 2 && isApprover"
        class="mr-2"
        size="sm"
        type="success"
        @click="approveModalVisible = true"
        >Approve</gf-button
      >
      <gf-button
        v-if="po.status === 2 && isApprover"
        class="mr-4"
        size="sm"
        type="danger"
        @click="rejectModalVisible = true"
        >Reject</gf-button
      >
      <gf-button
        v-if="po.status === 2"
        class="mr-2"
        size="sm"
        type="danger"
        @click="cancelModalVisible = true"
        >Cancel</gf-button
      >
      <gf-button
        v-if="(po.status === 5 || po.status === 6) && activeTab === 1"
        :disabled="!itemSelection.length > 0"
        class="mr-2"
        size="sm"
        type="primary"
        @click="receiveModalVisible = true"
        >Receive</gf-button
      >
      <gf-button
        v-if="(po.status === 5 || po.status === 6) && activeTab === 2"
        :disabled="!countingSelection.length > 0"
        class="mr-2"
        size="sm"
        type="primary"
        @click="qcModalVisible = true"
        >Start QC</gf-button
      >
      <gf-button
        v-if="(po.status === 5 || po.status === 6) && activeTab === 3"
        :disabled="!qcSelection.length > 0"
        class="mr-2"
        size="sm"
        type="primary"
        @click="paModalVisible = true"
        >To Warehouse</gf-button
      >
      <gf-button
        v-if="
          po.status === 2 ||
            po.status === 5 ||
            po.status === 6 ||
            po.status === 7
        "
        class="mr-2"
        size="sm"
        type="success"
        @click="print"
        >Print</gf-button
      >
      <gf-button
        v-if="po.status === 1 || po.status === 3 || po.status === 4"
        class="mr-2"
        size="sm"
        type="secondary"
        @click="edit"
        >Edit</gf-button
      >
      <gf-button
        v-if="po.status === 5 || po.status === 6 || po.status === 7"
        class="mr-2"
        size="sm"
        type="success"
        @click="paymentModalVisible = true"
        >Set Payment</gf-button
      >
      <gf-button
        v-if="po.status !== 8"
        size="sm"
        type="warning"
        @click="voidModalVisible = true"
        >Void</gf-button
      >
    </template>
    <template #header>
      <el-row>
        <el-col :lg="8" :md="8" :sm="24">
          <div class="detail">
            <div class="title">
              <span class="font-size-sm font-weight-normal pr-3">Address</span>
              <span class="font-size-sm font-weight-normal pr-3">Bill To</span>
              <span class="font-size-sm font-weight-normal pr-3">Ship To</span>
            </div>
            <div class="content">
              <span class="font-size-sm pl-3">{{
                $StringFormat(supplierAddress, 40)
              }}</span>
              <span class="font-size-sm pl-3">{{
                $StringFormat(billAddress, 40)
              }}</span>
              <span class="font-size-sm pl-3">{{
                $StringFormat(billAddress, 40)
              }}</span>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24">
          <div class="detail">
            <div class="title">
              <span class="font-size-sm font-weight-normal pr-3"
                >Issued Date</span
              >
              <span class="font-size-sm font-weight-normal pr-3"
                >Stock Due</span
              >
              <span class="font-size-sm font-weight-normal pr-3"
                >Payment Due</span
              >
            </div>
            <div class="content">
              <span class="font-size-sm pl-3">{{
                $DateFormat(po.issue_date)
              }}</span>
              <span class="font-size-sm pl-3">{{
                $DateFormat(po.stock_due)
              }}</span>
              <span class="font-size-sm pl-3">{{
                $DateFormat(po.payment_due)
              }}</span>
            </div>
          </div>
        </el-col>
        <el-col :lg="8" :md="8" :sm="24">
          <div class="detail">
            <div class="title">
              <span class="font-size-sm font-weight-normal pr-3"
                >Totals are</span
              >
              <span class="font-size-sm font-weight-normal pr-3">Email</span>
              <span class="font-size-sm font-weight-normal pr-3"
                >Payment Method</span
              >
            </div>
            <div class="content">
              <span v-if="po.is_inclusive === 0" class="font-size-sm pl-3"
                >Tax Exclusive</span
              >
              <span v-else-if="po.is_inclusive === 1" class="font-size-sm pl-3"
                >Tax Inclusive</span
              >
              <span v-else class="font-size-sm pl-3">No Tax</span>
              <span class="font-size-sm pl-3">{{ po.email || 'NA' }}</span>
              <span class="font-size-sm pl-3">{{
                po.payment_method || 'Not Set'
              }}</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </template>
    <el-dialog
      v-loading="approveModalLoading"
      :close-on-click-modal="false"
      :visible.sync="approveModalVisible"
      append-to-body
      title="Approve Purchase Order"
    >
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-primary svg-icon-xxl">
            <svg
              height="24px"
              version="1.1"
              viewBox="0 0 24 24"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect
                  fill="#000000"
                  height="2"
                  rx="1"
                  width="2"
                  x="11"
                  y="16"
                />
              </g>
            </svg>
          </span>
        </template>
        You are trying to approve Purchase Order #{{ po.po_number }}. Are you
        sure?
      </gf-alert>
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="approveModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="primary" @click="approve">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="rejectModalLoading"
      :close-on-click-modal="false"
      :visible.sync="rejectModalVisible"
      append-to-body
      title="Reject Purchase Order"
    >
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg
              height="24px"
              version="1.1"
              viewBox="0 0 24 24"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect
                  fill="#000000"
                  height="2"
                  rx="1"
                  width="2"
                  x="11"
                  y="16"
                />
              </g>
            </svg>
          </span>
        </template>
        By rejecting this Purchase Order. User need to resubmit it again for
        approval to proceed. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="rejectModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="reject">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="cancelModalLoading"
      :close-on-click-modal="false"
      :visible.sync="cancelModalVisible"
      append-to-body
      title="Cancel Purchase Order"
    >
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-danger svg-icon-xxl">
            <svg
              height="24px"
              version="1.1"
              viewBox="0 0 24 24"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect
                  fill="#000000"
                  height="2"
                  rx="1"
                  width="2"
                  x="11"
                  y="16"
                />
              </g>
            </svg>
          </span>
        </template>
        By cancelling this Purchase Order. You need to resubmit it again for the
        Approver to approve it. Are you sure?
      </gf-alert>
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="cancelModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="danger" @click="cancel">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="voidModalLoading"
      :close-on-click-modal="false"
      :visible.sync="voidModalVisible"
      append-to-body
      title="Void Purchase Order"
    >
      <gf-alert>
        <template #icon>
          <span class="svg-icon svg-icon-warning svg-icon-xxl">
            <svg
              height="24px"
              version="1.1"
              viewBox="0 0 24 24"
              width="24px"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
                <rect height="24" width="24" x="0" y="0" />
                <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                <rect fill="#000000" height="8" rx="1" width="2" x="11" y="7" />
                <rect
                  fill="#000000"
                  height="2"
                  rx="1"
                  width="2"
                  x="11"
                  y="16"
                />
              </g>
            </svg>
          </span>
        </template>
        <span v-if="inbound.completed.length === 0"
          >Voiding this Purchase Order will rollback all inbounds and revert all
          stock movements associated to it. If you wish to void a specific
          inbound instead, click items on completed tab you want to void. Are
          you sure?</span
        >
        <span v-else
          >Voiding these item/s will rollback all inbounds and revert all stock
          movements associated to it.</span
        >
      </gf-alert>
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="voidModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="warning" @click="voidPO">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="receiveModalLoading"
      :close-on-click-modal="false"
      :visible.sync="receiveModalVisible"
      append-to-body
      class="receive-dialog"
      title="Receive Form (Counting)"
    >
      <gf-form>
        <el-form ref="inbound" :model="inbound" :rules="inboundRules">
          <gf-table :data="inbound.counting">
            <el-table-column label="Name" min-width="150">
              <template slot-scope="slot">
                <span v-if="slot.row.item.variant.is_pack === 0"
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant.name }}</span
                >
                <span v-else
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant_base.name }} -
                  {{ slot.row.item.variant.name }} (Pack of
                  {{ slot.row.item.variant.pack_size }})</span
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="Expected" min-width="120">
              <template slot-scope="slot">
                {{ slot.row.expected }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="Actual" min-width="120">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'counting.' + slot.$index + '.actual'"
                  :rules="{ required: true, message: '', trigger: 'blur' }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.actual" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Remarks" min-width="180">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'counting.' + slot.$index + '.remarks'"
                  :rules="{
                    required:
                      parseFloat(slot.row.actual) !==
                      parseFloat(slot.row.expected)
                        ? true
                        : false,
                    message: '',
                    trigger: 'blur'
                  }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.remarks" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </gf-table>
        </el-form>
      </gf-form>
      <span
        ><b>*</b>
        <i
          ><b>Remarks</b> is required when <b>Expected Value</b> and
          <b>Actual Value</b> is not equal</i
        ></span
      >
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="receiveModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="primary" @click="submitCounting">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="qcModalLoading"
      :close-on-click-modal="false"
      :visible.sync="qcModalVisible"
      append-to-body
      class="receive-dialog"
      title="Receive Form (QC)"
    >
      <gf-form>
        <el-form ref="inbound" :model="inbound" :rules="inboundRules">
          <gf-table :data="inbound.qc">
            <el-table-column label="Name" min-width="130">
              <template slot-scope="slot">
                <span v-if="slot.row.item.variant.is_pack === 0"
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant.name }}</span
                >
                <span v-else
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant.variant.name }} -
                  {{ slot.row.item.variant.name }} (Pack of
                  {{ slot.row.item.variant.pack_size }})</span
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="Quantity" min-width="120">
              <template slot-scope="slot">
                {{ slot.row.expected }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="Usable" min-width="100">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'qc.' + slot.$index + '.actual'"
                  :rules="{ required: true, message: '', trigger: 'blur' }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.actual" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="Not Usable" min-width="130">
              <template slot-scope="slot">
                {{ $NumberFormat(slot.row.expected - slot.row.actual) }}
              </template>
            </el-table-column>
            <el-table-column label="Remarks" min-width="180">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'qc.' + slot.$index + '.remarks'"
                  :rules="{
                    required:
                      parseFloat(slot.row.actual) !==
                      parseFloat(slot.row.expected)
                        ? true
                        : false,
                    message: '',
                    trigger: 'blur'
                  }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.remarks" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </gf-table>
        </el-form>
      </gf-form>
      <span
        ><b>*</b>
        <i
          ><b>Remarks</b> is required when <b>Expected Value</b> and
          <b>Actual Value</b> is not equal</i
        ></span
      >
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="qcModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="primary" @click="submitQC">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="paModalLoading"
      :close-on-click-modal="false"
      :visible.sync="paModalVisible"
      append-to-body
      class="receive-dialog"
      title="Receive Form (Put Away)"
    >
      <gf-form>
        <el-form ref="inbound" :model="inbound" :rules="inboundRules">
          <gf-table :data="inbound.putaway">
            <el-table-column label="Name" min-width="150">
              <template slot-scope="slot">
                <span v-if="slot.row.item.variant.is_pack === 0"
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant.name }}</span
                >
                <span v-else
                  >{{ slot.row.item.product.name }} -
                  {{ slot.row.item.variant.variant.name }} -
                  {{ slot.row.item.variant.name }} (Pack of
                  {{ slot.row.item.variant.pack_size }})</span
                >
              </template>
            </el-table-column>
            <el-table-column align="center" label="Quantity" min-width="100">
              <template slot-scope="slot">
                {{ slot.row.expected }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="To Receive" min-width="100">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'putaway.' + slot.$index + '.actual'"
                  :rules="{ required: true, message: '', trigger: 'blur' }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.actual" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="Remarks" min-width="180">
              <template slot-scope="slot">
                <el-form-item
                  :prop="'putaway.' + slot.$index + '.remarks'"
                  :rules="{
                    required:
                      parseFloat(slot.row.actual) !==
                      parseFloat(slot.row.expected)
                        ? true
                        : false,
                    message: '',
                    trigger: 'blur'
                  }"
                  class="mb-0"
                  label-width="0"
                >
                  <el-input v-model="slot.row.remarks" size="mini"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </gf-table>
        </el-form>
      </gf-form>
      <span
        ><b>*</b>
        <i
          ><b>Remarks</b> is required when <b>Quantity</b> and
          <b>To Received Quantity</b> is not equal</i
        ></span
      >
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="paModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="primary" @click="submitPutaway">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <el-dialog
      v-loading="paymentModalLoading"
      :close-on-click-modal="false"
      :visible.sync="paymentModalVisible"
      append-to-body
      title="Set Payment Method"
    >
      <gf-form>
        <el-form label-width="140px">
          <el-form-item label="Payment Method">
            <el-select v-model="payment.method" style="width: 100%">
              <el-option value="Online Payment"></el-option>
              <el-option value="COD"></el-option>
              <el-option value="Terms"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="payment.method === 'Terms'" label="Terms (Days)">
            <el-input v-model="payment.term"></el-input>
          </el-form-item>
          <el-form-item v-if="payment.method === 'COD'" label="Type">
            <el-select v-model="payment.type" style="width: 100%">
              <el-option value="Cash"></el-option>
              <el-option value="Cheque"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </gf-form>
      <template #footer>
        <gf-button
          class="mr-2"
          type="secondary"
          @click="paymentModalVisible = false"
        >
          <template slot="icon">
            <span class="svg-icon svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <g
                    fill="#000000"
                    transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                  >
                    <rect height="2" rx="1" width="16" x="0" y="7" />
                    <rect
                      height="2"
                      opacity="0.3"
                      rx="1"
                      transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                      width="16"
                      x="0"
                      y="7"
                    />
                  </g>
                </g>
              </svg>
            </span>
          </template>
          Close
        </gf-button>
        <gf-button type="primary" @click="setPayment">
          <template slot="icon">
            <span class="svg-icon svg-icon-white svg-icon-sm">
              <svg
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g
                  fill="none"
                  fill-rule="evenodd"
                  stroke="none"
                  stroke-width="1"
                >
                  <rect height="24" width="24" x="0" y="0" />
                  <circle cx="12" cy="12" fill="#000000" opacity="0.3" r="10" />
                  <path
                    d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g>
              </svg>
            </span>
          </template>
          Confirm
        </gf-button>
      </template>
    </el-dialog>
    <div
      v-if="po.status === 5 || po.status === 6 || po.status === 7"
      class="tab mb-2"
    >
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :class="
          tab.id === activeTab
            ? 'bg-primary text-white'
            : 'text-muted text-hover-primary'
        "
        class="font-size-xs font-weight-bolder"
        @click="tabClick(tab.id)"
      >
        <span>{{ tab.name }}</span>
      </div>
    </div>
    <div>
      <item-table
        v-if="activeTab === 1"
        :data="items"
        :po="po"
        @selection-change="itemSelectionChange"
      ></item-table>
      <count-table
        v-else-if="activeTab === 2"
        :data="counts"
        :po="po"
        @selection-change="countingSelectionChange"
      ></count-table>
      <qc-table
        v-else-if="activeTab === 3"
        :data="qc"
        :po="po"
        @selection-change="qcSelectionChange"
      ></qc-table>
      <put-away-table
        v-else-if="activeTab === 4"
        :data="putaway"
        :po="po"
        @selection-change="putawaySelectionChange"
      ></put-away-table>
    </div>
    <div class="footer mt-2">
      <div class="note">
        <span>{{ po.message }}</span>
      </div>
      <div class="gf-summary">
        <ul class="summary">
          <li class="summary__item">
            <span class="text">Total Units</span>
            <span class="value">{{ $NumberFormat(po.unit_counts) }}</span>
          </li>
          <li v-if="po.is_inclusive !== 2" class="summary__item">
            <span class="text">Subtotal</span>
            <span class="value">{{ $NumberFormat(po.subtotal) }}</span>
          </li>
          <div v-if="po.is_inclusive !== 2">
            <li
              v-for="(vat, index) in po.vats"
              :key="index"
              class="summary__item"
            >
              <span class="text"
                >{{ po.is_inclusive ? 'Including' : 'Plus' }} VAT ({{
                  vat.rate
                }}%)</span
              >
              <span class="value">{{ $NumberFormat(vat.value) }}</span>
            </li>
          </div>
          <li class="summary__item">
            <span class="text">Total</span>
            <span class="value">{{ $NumberFormat(po.total) }}</span>
          </li>
        </ul>
      </div>
    </div>
  </gf-content>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  justify-content: center;

  .title,
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    align-items: flex-end;

    span {
      border-right: 1px solid #ebedf3;
    }
  }

  .content {
    align-items: flex-start;
  }
}

.tab {
  display: flex;

  div {
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
// Services
import PurchaseOrderService from '@/services/v1/PurchaseOrder'
import PurcaseOrderItemService from '@/services/v1/PurchaseOrderItem'
import PurchaseOrderInboundService from '@/services/v1/PurchaseOrderInbound'
import ItemTable from './components/ItemTable'
import CountTable from './components/CountTable'
import QcTable from './components/QCTable'
import PutAwayTable from './components/PutAwayTable'

export default {
  components: {
    ItemTable,
    CountTable,
    QcTable,
    PutAwayTable
  },
  data() {
    return {
      purchaseOrderId: this.$route.params.id,

      // ui
      loading: false,

      po: {
        id: null,
        po_number: null,
        issue_date: null,
        stock_due: null,
        payment_due: null,
        email: null,
        is_inclusive: null,
        unit_counts: null,
        message: null,
        vats: [],
        landed_cost: [],
        supplier: {
          name: null
        },
        supplier_address: {
          label: null
        },
        bill_to: {
          name: null
        },
        ship_to: {
          name: null
        },
        currency: {
          name: null
        }
      },
      payment: {
        method: 'Online Payment',
        term: '',
        type: 'Cash'
      },
      items: [],
      counts: [],
      qc: [],
      putaway: [],
      isApprover: false,

      // modal
      approveModalVisible: false,
      approveModalLoading: false,
      rejectModalVisible: false,
      rejectModalLoading: false,
      cancelModalVisible: false,
      cancelModalLoading: false,
      receiveModalVisible: false,
      receiveModalLoading: false,
      qcModalVisible: false,
      qcModalLoading: false,
      paModalVisible: false,
      paModalLoading: false,
      voidModalVisible: false,
      voidModalLoading: false,
      paymentModalVisible: false,
      paymentModalLoading: false,

      // selection
      itemSelection: [],
      countingSelection: [],
      qcSelection: [],
      putawaySelection: [],
      inbound: {
        counting: [],
        qc: [],
        putaway: [],
        completed: []
      },

      // modal
      showCounting: false,
      showQC: false,
      showPutaway: false,

      // rules
      inboundRules: {},

      // Rendering
      tabs: [
        { id: 1, name: 'Pending' },
        { id: 2, name: 'Quality Control' },
        { id: 3, name: 'Put Away' },
        { id: 4, name: 'Completed' }
      ],
      countingTabs: [
        { id: 1, name: 'Steps' },
        { id: 2, name: 'Table' }
      ],
      activeTab: 1,
      activeCountingTab: 1
    }
  },
  computed: {
    supplierAddress() {
      let address = ''

      if (this.po.supplier_address.address_line_1) {
        address += this.po.supplier_address.address_line_1
      }

      if (this.po.supplier_address.address_line_2) {
        address += ' ' + this.po.supplier_address.address_line_2
      }

      if (this.po.supplier_address.city) {
        address += ', ' + this.po.supplier_address.city
      }

      if (this.po.supplier_address.state) {
        address += ', ' + this.po.supplier_address.state
      }

      return address
    },
    billAddress() {
      let address = ''

      if (this.po.bill_to.address_line_1) {
        address += this.po.bill_to.address_line_1
      }

      if (this.po.bill_to.address_line_2) {
        address += ' ' + this.po.bill_to.address_line_2
      }

      if (this.po.bill_to.city) address += ', ' + this.po.bill_to.city
      if (this.po.bill_to.state) address += ', ' + this.po.bill_to.state

      return address
    }
  },
  methods: {
    // handler
    edit() {
      this.$router.push({
        name: 'purchase-order-edit',
        params: { id: this.purchaseOrderId }
      })
    },
    print() {
      this.$router.push({
        name: 'purchase-order-print',
        params: { id: this.purchaseOrderId }
      })
    },
    tabClick(tab) {
      this.activeTab = tab
      this.items = []
      this.qc = []
      this.counts = []
      this.putaway = []
      this.loadTabItems()
    },
    async approve() {
      try {
        this.approveModalLoading = true
        const poService = new PurchaseOrderService(this.purchaseOrderId)
        await poService.approve()
        this.$message.success(`${this.po.po_number} successfully approved`)

        this.approveModalVisible = false
        await this.getPurchaseOrder()
        this.loadTabItems()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.approveModalLoading = false
      }
    },
    async reject() {
      try {
        this.rejectModalLoading = true
        const poService = new PurchaseOrderService(this.purchaseOrderId)
        await poService.reject()
        this.$message.success(`${this.po.po_number} has been rejected`)

        this.rejectModalVisible = false
        await this.getPurchaseOrder()
        this.loadTabItems()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.rejectModalLoading = false
      }
    },
    async cancel() {
      try {
        this.cancelModalLoading = true
        const poService = new PurchaseOrderService(this.purchaseOrderId)
        await poService.cancel()
        this.$message.success(`${this.po.po_number} has been cancelled`)

        this.cancelModalVisible = false
        await this.getPurchaseOrder()
        this.loadTabItems()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.cancelModalLoading = false
      }
    },
    async voidPO() {
      try {
        this.voidModalLoading = true
        const poService = new PurchaseOrderService(this.purchaseOrderId)

        if (this.inbound.completed.length === 0) {
          await poService.void()
          this.$message.success(`${this.po.po_number} was successfully void`)
          this.activeTab = 1
        } else {
          await poService.void(this.inbound.completed)
          this.$message.success(
            `${this.inbound.completed.length} item/s was successfully void`
          )
          this.activeTab = 3
          this.inbound.completed = []
          this.putawaySelection = []
        }

        this.voidModalVisible = false
        await this.getPurchaseOrder()
        this.loadTabItems()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.voidModalLoading = false
      }
    },
    async setPayment() {
      try {
        this.paymentModalLoading = true

        let paymentMethod = this.payment.method
        if (this.payment.method === 'Terms') {
          paymentMethod += ` (${this.payment.term} Days)`
        } else if (this.payment.method === 'COD') {
          paymentMethod += ` (${this.payment.type})`
        }

        const poService = new PurchaseOrderService(this.purchaseOrderId)
        await poService.payment({ payment: paymentMethod })
        this.$message.success('Payment successfully set')

        this.paymentModalVisible = false
        this.getPurchaseOrder()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.paymentModalLoading = false
      }
    },
    async loadTabItems() {
      if (this.activeTab === 1) {
        await this.getItems()
        return
      }

      if (this.activeTab === 2) {
        await this.getCounts()
        return
      }

      if (this.activeTab === 3) {
        await this.getQC()
        return
      }

      if (this.activeTab === 4) {
        await this.getPutaway()
      }
    },
    itemSelectionChange(selection) {
      this.itemSelection = selection
      this.inbound.counting = []

      for (let i = 0; i < selection.length; i++) {
        const sel = selection[i]
        this.inbound.counting.push({
          item: sel,
          purchase_order_id: this.$route.params.id,
          purchase_order_item_id: sel.id,
          expected: sel.quantity,
          actual: 0,
          remarks: ''
        })
      }
    },
    countingSelectionChange(selection) {
      this.countingSelection = selection
      this.inbound.qc = []

      for (let i = 0; i < selection.length; i++) {
        const sel = selection[i]
        this.inbound.qc.push({
          item: sel,
          purchase_order_id: this.$route.params.id,
          purchase_order_item_id: sel.id,
          expected: sel.inbound[0].actual,
          actual: 0,
          remarks: ''
        })
      }
    },
    qcSelectionChange(selection) {
      this.qcSelection = selection
      this.inbound.putaway = []

      for (let i = 0; i < selection.length; i++) {
        const sel = selection[i]
        this.inbound.putaway.push({
          inbound_id: sel.inbound[0].id,
          item: sel,
          purchase_order_id: this.$route.params.id,
          purchase_order_item_id: sel.id,
          expected: sel.inbound[0].actual - sel.inbound[0].received_qty,
          actual: 0,
          remarks: ''
        })
      }
    },
    putawaySelectionChange(selection) {
      this.putawaySelection = selection
      this.inbound.completed = []

      for (let i = 0; i < selection.length; i++) {
        const sel = selection[i]
        this.inbound.completed.push(sel.purchase_order_item_id)
      }
    },
    submitCounting() {
      this.$refs.inbound
        .validate()
        .then(async () => {
          try {
            this.receiveModalLoading = true
            // Clean
            for (let i = 0; i < this.inbound.counting.length; i++) {
              const ct = this.inbound.counting[i]
              delete ct.item
            }

            const piService = new PurchaseOrderInboundService(
              this.purchaseOrderId
            )
            await piService.count(this.inbound.counting)
            this.$message.success(
              `${this.inbound.counting.length} item/s transition to Quality Control Step`
            )
            this.inbound.counting = []
            this.itemSelection = []

            await this.getPurchaseOrder()
            await this.loadTabItems()

            this.receiveModalVisible = false
          } catch (error) {
            this.$Error(error)
          } finally {
            this.receiveModalLoading = false
          }
        })
        .catch(() => {})
    },
    submitQC() {
      this.$refs.inbound
        .validate()
        .then(async () => {
          try {
            this.qcModalLoading = true
            // Clean
            for (let i = 0; i < this.inbound.qc.length; i++) {
              const qc = this.inbound.qc[i]

              if (parseFloat(qc.actual) > parseFloat(qc.expected)) {
                this.$notify.error({
                  title: 'Error',
                  message: `Sorry. But Usable value cannot exceed Quantity of the item #${i +
                    1}.`
                })
                return
              }

              delete qc.item
            }

            const piService = new PurchaseOrderInboundService(
              this.purchaseOrderId
            )
            await piService.qc(this.inbound.qc)
            this.$message.success(
              `${this.inbound.qc.length} item/s transition to Put Away Step`
            )
            this.inbound.qc = []
            this.countingSelection = []

            await this.getPurchaseOrder()
            await this.loadTabItems()

            this.qcModalVisible = false
          } catch (error) {
            this.$Error(error)
          } finally {
            this.qcModalLoading = false
          }
        })
        .catch(() => {})
    },
    submitPutaway() {
      this.$refs.inbound
        .validate()
        .then(async () => {
          try {
            this.paModalLoading = true
            // Clean
            for (let i = 0; i < this.inbound.putaway.length; i++) {
              const qc = this.inbound.putaway[i]

              if (parseFloat(qc.actual) > parseFloat(qc.expected)) {
                this.$notify.error({
                  title: 'Error',
                  message: `Sorry. But Received value cannot exceed Quantity of the item #${i +
                    1}.`
                })
                return
              }

              delete qc.item
            }

            const piService = new PurchaseOrderInboundService(
              this.purchaseOrderId
            )
            await piService.putAway(this.inbound.putaway)
            this.$message.success(
              `${this.inbound.putaway.length} item/s transition to Completed`
            )
            this.inbound.putaway = []
            this.qcSelection = []

            await this.getPurchaseOrder()
            await this.loadTabItems()

            this.paModalVisible = false
          } catch (error) {
            this.$error500()
          } finally {
            this.paModalLoading = false
          }
        })
        .catch(() => {})
    },

    // Service Fetch
    async getPurchaseOrder() {
      try {
        this.loading = true
        const poService = new PurchaseOrderService(this.purchaseOrderId)
        const result = await poService.get()
        this.po = {
          ...result.data,
          vats: result.data.vats
        }
      } catch (error) {
        this.$Error(error)
        this.$router.back()
      } finally {
        this.loading = false
      }
    },
    async getItems() {
      try {
        const piService = new PurcaseOrderItemService(this.purchaseOrderId)
        const result = await piService.list(null, null, {
          'item.status': { '=': 1 }
        })
        this.items = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getCounts() {
      try {
        const piService = new PurchaseOrderInboundService(this.purchaseOrderId)
        const result = await piService.list()
        this.counts = result.data.rows
      } catch (error) {
        this.$Error(error)
      }
    },
    async getQC() {
      try {
        const piService = new PurchaseOrderInboundService(this.purchaseOrderId)
        const result = await piService.getQC()
        this.qc = result.data.rows
      } catch (error) {
        this.$error500()
      }
    },
    async getPutaway() {
      try {
        const piService = new PurchaseOrderInboundService(this.purchaseOrderId)
        const result = await piService.getPutaway()
        // this.putaway = result.data.rows
        for (let i = 0; i < result.data.rows.length; i++) {
          const data = result.data.rows[i]

          for (let j = 0; j < data.inbound.length; j++) {
            const inbound = data.inbound[j]
            this.putaway.push({
              purchase_order_item_id: data.id,
              product: data.product,
              variant: data.variant,
              quantity: inbound.actual,
              cost: data.cost,
              tax: data.tax,
              remarks: inbound.remarks,
              created_at: inbound.created_at
            })
          }
        }
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted() {
    this.loading = true
    this.isApprover = this.$GetPermission('PO_APPROVAL')

    this.$emit('update:active', 1)
    await this.getPurchaseOrder()
    await this.loadTabItems()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Stock control' },
      { title: 'Purchase Order' },
      { title: this.po.po_number },
      { title: 'Details' }
    ])
  }
}
</script>
